import React, { FunctionComponent } from 'react';
import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { useNetworkStateQuery } from '../api/hooks';
import { useTranslation } from 'react-i18next';
import { Cached as LinkUnknownIcon } from '@mui/icons-material';
import { EthernetIcon as LinkUpIcon } from '@mui-extra/icons/EthernetIcon';
import { EthernetOffIcon as LinkDownIcon } from '@mui-extra/icons/EthernetOffIcon';

export const NetworkIfaceStateCard: FunctionComponent<{
    iface: string;
}> = ({ iface }): React.ReactElement | null => {
    const { t } = useTranslation();
    const { data: networkState } = useNetworkStateQuery();

    const { label: linkLabel, icon: linkIcon } = React.useMemo(() => {
        if (!networkState?.interfaceStates[iface]) {
            return { label: t('network-state-link-unknown'), icon: <LinkUnknownIcon /> };
        } else {
            if (networkState.interfaceStates[iface].link.state === 'up') {
                return { label: t('network-state-link-up'), icon: <LinkUpIcon /> };
            } else {
                return { label: t('network-state-link-down'), icon: <LinkDownIcon /> };
            }
        }
    }, [iface, networkState, t]);

    return (
        <Card variant="outlined" sx={{ marginBottom: '2rem', width: '100%' }}>
            <Box sx={{ p: 1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="h6" component="div" sx={{ userSelect: 'none' }}>
                        {linkLabel}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                        {linkIcon}
                    </Typography>
                </Stack>
            </Box>
            <Divider />
            <Grid container direction="row">
                <Grid item container direction="column" xs={4} sx={{ p: 1 }}>
                    <Grid item>
                        <Typography variant="body1" sx={{ userSelect: 'none' }}>
                            {t('ip-address')}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ wordWrap: 'break-word', width: '100%', marginTop: '0.25rem' }}>
                        <Typography color="text.secondary" variant="body2">
                            {networkState?.interfaceStates[iface]?.ip_config.address || 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
                <Grid item container direction="column" xs={4} sx={{ p: 1 }}>
                    <Grid item>
                        <Typography variant="body1" sx={{ userSelect: 'none' }}>
                            {t('netmask')}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ wordWrap: 'break-word', width: '100%', marginTop: '0.25rem' }}>
                        <Typography color="text.secondary" variant="body2">
                            {networkState?.interfaceStates[iface]?.ip_config.netmask || 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
                <Grid item container direction="column" xs={4} sx={{ p: 1 }}>
                    <Grid item>
                        <Typography variant="body1" sx={{ userSelect: 'none' }}>
                            {t('default-gw')}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ wordWrap: 'break-word', width: '100%', marginTop: '0.25rem' }}>
                        <Typography color="text.secondary" variant="body2">
                            {networkState?.interfaceStates[iface]?.ip_config.gateway || 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};
